.u-button--brand {
  background: #6200ee !important;
  color: #ffffff !important;
}

/* drawer header button */

.wallet__button {
  display: flex;
  align-items: center;
  border: none;
  background: transparent;
  padding-left: 0px;
  height: 100%;
  cursor: pointer;
}

.wallet__button :first-child {
  margin-right: 5px;
}

.wallet__button svg {
  color: #323232;
}

.wallet__button span {
  vertical-align: middle;
  text-transform: uppercase;
  line-height: 18px;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 2px;
  height: 100%;
  color: #323232;
}

.bp3-disabled {
  opacity: 0.3;
  filter: saturate(0%);
}
