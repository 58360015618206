.empty_project_body {
    color: #989898;
    line-height: 30px;
    paddingBottom: 20px;
    width: 559px;
    height: 91px;
    textAlign: justify;
}

.empty_project_text_container {
    height: 321px;
    padding: 30px
}

.empty_project_thumbnail {
    height: 400px;
    width: 100%;
    justifyContent: center;
    alignItems: flex-end;
    display: flex;
    backgroundColor: #E5E5E5;
    marginBottom: 50px
}

.default-view_container {
    width: 65%;
    marginRight: 50px;
}

.default-view_subheading {
    paddingBottom: 20px;
    paddingLeft: 30px;
    paddingTop: 10px
}