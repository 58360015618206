.empty__dashboard_create-org-wrapper {
  display: flex;
  gap: 16px;
  justify-content: center;
  margin-top: 16px;
}

.dashboard__spinner {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Global Operations Styles */

.global__operations-outer-wrapper {
  padding-left: 30px;
  padding-bottom: 30px;
  background-color: #f8fafc;
}

.global__operations-widget__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.global__operations-widget-export-button {
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.05) 0%,
      rgba(255, 255, 255, 5e-6) 100%
    ),
    #394b59 !important;
  color: #ffffff !important;
  /*width: 130px;*/
  height: 35px;
  line-height: 20px !important;
  margin-top: 15px;
  margin-right: 30px;
  border-radius: 3px;
  white-space: nowrap;
}

.global__operations-widget-grid {
  display: grid;
  grid-template-columns: 3fr 1.5fr;
  grid-gap: 20px;
  margin-right: 30px;
  margin-top: 30px;
}

.global__operations-block-view {
  display: block;
  min-height: 22rem;
}

.global__operations-widget-grid-left {
  display: grid;
  grid-template-rows: 130px 1fr;
  gap: 30px;
}

.global__operations-widget-stats {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
}

.global__operations-widget-transactions {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 30px;
}

/* Project Activity Charts */

.activity__charts-outer-wrapper {
  padding: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
  background-color: #f8fafc;
}

.activity__charts-outer-wrapper--loading {
  grid-auto-rows: min-content;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-gap: 20px;
  background-color: #f8fafc;
  /* TODO: make this grow to remaining height, rather than hardcoded */
  min-height: 350px;
}

.activity__charts-widget {
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: solid #edeff1 1px;
  border-radius: 5px;
  background-color: #fffffe;
}

.demographic_insight-chart_header {
  font-size: 17px;
  font-family: SFPro;
  font-weight: 700;
  margin-bottom: 10px;
}
