.error_screen__wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100vh;
  padding: 100px;
  background: #f4f4f4;
}

.error_screen__content_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.error_screen__title {
  font-family: "SF Pro Text" sans-serif;
  font-size: 50px;
  color: #2c2c2c;
}

.error_screen__subtitle {
  font-family: Roboto;
  font-size: 36px;
  color: #2c2c2c;
}

.error_screen__link {
  font-size: 20px;
}

.error_screen__status_code {
  font-family: Roboto Mono;
  font-size: 200px;
  color: #2c2c2c;
}

.error_screen__icon_wrapper {
  display: flex;
  align-items: center;
}

.error_screen__icon_svg {
  width: 100px;
  height: 100px;
  margin: 0;
}

.error_screen__icon_text {
  font-size: 40px;
  margin: 0px;
  padding-left: 20px;
  color: #2c2c2c;
}
