.input_wrapper {
    width: 100%; 
    display: flex;
    height: 40px;
}

.input_error_wrapper {
    font-size: 10px; 
    color: red; 
    padding: 0;
    margin: 0
}

.input_container {
    height: 50px;
    width: 400px;
    margin-bottom: 5px;
}

.input_field {
 height: 30px; 
 width: 300px; 

}

.organization__form-input-list-item {
    display: flex;
    align-items: flex-start;
    margin-top: 10px;
    justify-content: space-between;
    width: 90%;
    height: 40px;
}