.popover__progress {
  margin-top: 5px;
}

.red {
  color: red;
}

.table_wrapper {
  overflow: scroll;
}