.analytics-container {
  height: 100px;
  width: 100%;
}

.flex-container {
  display: flex;
}

.analytics-card_header {
  font-size: 17px;
  margin-top: 0px;
  margin-bottom: 10px;
  font-family: SFPro;
  font-weight: 700;
}

.analytics-card_right-header,
.analytics-card_left-header {
  font-family: SFPro;
  font-weight: 500;
}

.analytics-card_content-wrapper {
  color: white;
  height: 100px;
  width: 100%;
  padding: 20px 0px 0px 20px;
}

.transaction-card_content-wrapper {
  color: black;
  width: 100%;
  height: 100px;
  padding: 20px 0px 0px 20px;
  background-color: #ffffff;
  border-radius: 6px;
}

.transaction-card_content-title {
  color: #808080;
  font-family: SFPro;
  font-weight: 500;
}

.transaction-card_content-loader {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.analytics-card_content-count {
  font-size: 24px;
  font-family: SFMono;
  font-weight: 600;
}

.left-border-radius {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.right-border-radius {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.grey-background {
  background-color: #333333;
}

.black-background {
  background-color: #1e1e1e;
}
