.sidebar__menu span.bp3-popover-target > div button {
  align-items: center !important;
}

.avatar__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border-radius: 50%;
  background-color: #6200ee;
  color: #ffffff;
}
