.bp3-menu > ul {
  display: flex;
  padding: 0;
  padding: "none" !important;
}

.sidebar__menu .bp3-button-text {
  color: #6200ee;
  font-weight: 700;
}

/* styles override blueprint.js to allow for dropdown container to be same width as selector */
.sidebar__menu-list_item {
  background-color: #ffffff !important;
  justify-content: center;
  width: 250px;
  word-wrap: break-word;
}

.sidebar__menu-list_item div {
  align-self: center;
  color: #6200ee;
}

.sidebar__menu button.organization__select-button {
  box-shadow: none;
  background: none;
  font-size: 16px;
  border-radius: 0;
  height: 60px;
}

.global__operations-outer-wrapper button.project__select-button {
  box-shadow: none;
  background: none;
  font-size: 20px;
  border-radius: 0;
  font-weight: 700;
  margin-top: 15px;
  padding-left: 0px;
}

.global__operations-outer-wrapper button.project__select-button:focus {
  outline: none;
}

.global__operations-outer-wrapper button.project__select-button:hover {
  box-shadow: none;
  background: none;
}

.project__select__menu-list_item {
  background-color: #ffffff !important;
  justify-content: center;
  width: 250px;
  word-wrap: break-word;
}

.project__select__menu-list_item div {
  align-self: center;
  color: #2b2b2b;
}

.project_select_loader {
  padding-bottom: 10px;
  padding-top: 10px;
  padding-left: 60px;
}
