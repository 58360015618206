.login__form-outer_wrapper {
  display: flex;
  height: 100vh;
}

.login__form-wrapper--dark {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  height: 100%;
  width: 40%;
  background-color: #2c2c2c;
  padding-left: 150px;
}

.login__card-title_wrapper {
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 60%;
  background-color: #6200ee;
  border-radius: 20px 0px 0px 20px;
  padding-left: 40px;
  padding-right: 40px;
  max-width: 450px;
}

.login__form-wrapper--light {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background: #eeeefe;
  padding-right: 150px;
}

.login__card {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60%;
  border-radius: 0px 20px 20px 0px !important;
  max-width: 800px;
}

.login__card-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 65%;
  padding-left: 10%;
  padding-right: 10%;
  padding-bottom: 50px;
}

.login__card-form h1 {
  color: #323232;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 36px;
}

.login__card-input-wrapper {
  height: 50px;
  margin-bottom: 10px;
}

.login__card-input_label {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #323232;
  padding-top: 10px;
  padding-bottom: 10px;
}

.login__card-icon {
  display: flex;
  align-items: center;
  text-align: center;
}

.login__card-icon h1 {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 36px;
  color: #ffffff;
  padding: 0px;
  margin-left: 10px;
}

.login__card-title_wrapper h2 {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 36px;
  color: #ffffff;
  padding: 0px;
  margin: 0px;
}

.login__card-title_wrapper--text {
  margin: 20px;
}

.login__card-title_wrapper--content {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #ffffff;
  opacity: 0.7;
  padding-top: 10px;
  padding-bottom: 10px;
}

.login__form-wrapper-infobox {
  background: rgba(45, 45, 45, 0.3) !important;
  border-radius: 3px;
  padding: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 10px;
}

.login__form-wrapper-infobox--responsive {
  display: none;
}

.login__form-wrapper-infobox_title_wrapper {
  display: flex;
  align-items: center;
  padding: 10px;
}

.activity__dot {
  min-width: 8px;
  min-height: 8px;
  width: 8px;
  height: 8px;
  background: #41cf4c;
  border-radius: 8px;
  margin-right: 15px;
}

.login__form-wrapper-infobox-title-text {
  padding: 0px;
  margin: 0px;
  font-size: 14px;
  color: #ffffff;
}

.login__form-wrapper-infobox-info-text {
  font-size: 10px;
  color: #ffffff;
  opacity: 0.7;
  padding: 0;
  margin: 0;
  margin-left: 24px;
}

.login__button {
  background-color: #6200ee !important;
  color: #ffffff !important;
  padding-left: 50px !important;
  padding-right: 50px !important;
  letter-spacing: 0.1 !important;
  font-weight: bold;
}

.login__page-link-text {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
}

.login__page-link-text--purple {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #6200ee;
}

.login__page-link-text--light {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #ffffff;
  opacity: 0.4;
}

.login__page-subtext_wrapper_one {
  width: 100%;
  padding-top: 20px;
  max-width: 450px;
  padding-left: 50px;
}

.login__page-subtext_wrapper_two {
  width: 100%;
  padding-top: 20px;
  width: 40%;
  padding-left: 50px;
}

.login__page-subtext_wrapper_three {
  display: none;
}

.forgot-password__link-wrapper {
  width: 8rem;
}

.forgot-password__link {
  cursor: pointer;
  color: #106ba3;
  margin-bottom: 1rem;
}

.login__page-language-selector {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 5;
}

/* mid-width, tall viewport */
@media only screen and (max-width: 1450px) {
  .login__form-wrapper--dark {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    height: 100%;
    width: 40%;
    background-color: #2c2c2c;
    padding-left: 100px;
  }
  .login__form-wrapper--light {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background: #eeeefe;
    padding-right: 100px;
  }
  .login__card-form {
    width: 85%;
  }
  .login__form-wrapper-infobox-title-text {
    font-size: 14px;
  }

  .login__form-wrapper-infobox-info-text {
    font-size: 10px;
  }
}

/* thin and tall viewport */
@media only screen and (max-width: 1000px) {
  .login__card {
    height: 100%;
  }
  .login__form-outer_wrapper {
    flex-direction: column;
  }

  .login__card-icon h1 {
    font-size: 18px;
  }

  .login__card-form {
    width: 100%;
    margin-left: 40px;
    margin-right: 20px;
  }

  .login__form-wrapper--dark {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    height: 30%;
    width: 100%;
    background-color: #2c2c2c;
    padding-left: 100px;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .login__form-wrapper--light {
    background: #eeeefe;
    padding-right: 0px;
    padding-top: 0px;
    padding-bottom: 100px;
    padding-left: 100px;
    height: 60%;
  }

  .login__card {
    margin-top: 65px;
    border-radius: 0px 0px 0px 20px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 0px;
    padding: 10px;
  }

  .login__card-form {
    padding: 0px;
  }

  .login__card-form h1 {
    font-size: 20px;
    margin: 0;
  }

  .login__card-form p {
    font-size: 12px;
  }

  .login__card-input_label {
    font-size: 12px;
    padding: 2px;
  }

  .login__card-title_wrapper {
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    background-color: #6200ee;
    border-radius: 20px 0px 0px 0px;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 10px;
    padding-bottom: 20px;
    max-width: 100%;
  }

  .login__card-title_wrapper h2 {
    font-size: 20px;
    line-height: 24px;
  }

  .login__form-wrapper-infobox {
    display: none;
  }

  .login__form-wrapper-infobox--responsive {
    background: rgb(98, 0, 238, 0.3) !important;
    width: 85%;
    border-radius: 3px;
    padding: 20px;
    margin-top: 50px;
    margin-left: 15%;
    display: block;
  }

  .login__card-title_wrapper p {
    font-size: 12px;
  }

  .login__page-subtext_wrapper_one {
    display: none;
  }

  .login__page-subtext_wrapper_two {
    display: none;
  }

  .login__page-subtext_wrapper_three {
    display: inline-flex;
    justify-content: space-evenly;
    background-color: #eeeefe;
    color: black;
    width: 100%;
  }
}

/* thin & short viewport */
@media only screen and (max-width: 1000px) and (max-height: 700px) {
  .login__form-wrapper--dark {
    height: 35%;
  }
  .login__form-wrapper--light {
    padding-bottom: 40px;
    height: 55%;
  }
  .login__card-form h1 {
    font-size: 18px;
  }
  .login__form-wrapper-infobox--responsive {
    background: rgb(98, 0, 238, 0.3) !important;
    width: 85%;
    border-radius: 3px;
    padding: 20px;
    margin-top: 20px;
    margin-left: 15%;
    display: block;
  }
}

/* wide & short viewport */
@media only screen and (min-width: 1000px) and (max-height: 700px) {
  .login__card-title_wrapper--text {
    margin: 0px;
  }
}
