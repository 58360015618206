.page_wrapper {
    width: 100vw;
    height: 100vh;
    display: block;
    background-color: #eeeefe;
}

.nav {
    background-color: #6200ee;
     height: 25vh; 
     display: flex; 
     flex-direction: column;
      padding-bottom: 20px;
}

.main {
    width: 100%; 
    height: 50vh; 
    display: flex; 
    justify-content: center
}

.nav_group_top {
    display: flex; 
    justify-content: space-between; 
    margin-top: 5px
}

.nav_group_bottom {
    margin-left: 30px; 
    margin-top: 20px;
    color: white;
    font-family: Roboto
}

.nav_group_heading {
    color: white;
    font-size: 25px;
    font-weight: bold; 
    font-family: Roboto
}

.nav_group_left{
    margin-left: 20px; 
    margin-top: 40px
}

.login__form {
    width: 100%;
    display: flex; 
    justify-content: center; 
    align-items: center;
    flex-direction: column;
}

.login__success__container {
    width: 60%; 
    height: 50%
}

.input__wrapper {
    width: 200px;
    height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 5px;
}