.settings__container {
  display: grid;
  grid-template: "sb sc";
  grid-template-columns: 270px 1fr;
  grid-gap: 10px;
}

.settings__breadcrumbs {
  border-bottom: 1px solid #e4e4e4;
}

.settings__sidebar {
  /* padding: 20px; */
  border-right: 1px solid #e4e4e4;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 250px;
}

.settings__content {
  height: 100%;
}

.settings__sidebar a.active {
  background-color: #6200ee;
  color: white;
}

.settings__sidebar .sidebar__link {
  display: block;
  padding: 8px 12px;
  border-radius: 3px;
  color: #323232;
}

/* Organization Settings Account */

.settings__card-container {
  /*max-width: 760px;*/
}

.settings__card-step {
  font-family: "SF Pro Text" sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 18px;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #323232;
}

.settings__card-wrapper {
  margin-bottom: 20px;
  max-width: 768px;
}

.settings__card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.settings__card-title_wrapper {
  display: flex;
  flex-direction: column;
}

.settings__card-title {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  color: #323232;
  margin-bottom: 10px;
  margin-top: 0px;
}

.settings__status_indicator {
  margin-left: 10px;
}

.settings__card-subtitle {
  font-family: "SF Pro Text", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #5c7080;
}

.settings__card-input-list {
  padding: 0px;
  list-style-type: none;

}

.settings__card-input-list-item {
  display: flex;
  align-items: center;
  margin-top: 10px;
  justify-content: space-between;
  width: 90%;
}

.settings__card-upload_select_wrapper {
  width: 300px;
}

.settings__card-file_upload_select {
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.8) 0%,
      rgba(255, 255, 255, 8e-5) 100%
    ),
    #f5f8fa;
  box-shadow: inset 0px -1px 1px rgba(16, 22, 26, 0.1);
  border-radius: 3px;
}

.settings__card-input-list-item_dropdown {
  width: 200px;
}

.settings__card-input-list-item label {
  min-width: 200px;
  padding-top: 5px;
}

.forgot__password-text {
  margin-left: 5px;
  font-size: 10px !important;
  color: #6200ee !important;
}

.settings__card-step_button_container {
  display: flex;
  justify-content: space-between;
}

.settings__card-step_button_wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
}

.settings__card-step_button_wrapper p {
  margin-right: 10px;
  padding-top: 5px;
}

.settings__card-team_management--empty_wrapper {
  background-image: url("./settings_graphic.png");
  background-size: cover;
  height: 200px;
  margin-left: -20px;
  margin-right: -20px;
  margin-bottom: -20px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
}

.settings__card-team_management--empty_wrapper h4 {
  font-family: "SF Pro Text" sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  line-height: 22px;
  text-align: center;
  color: #191459;
  padding: 0px;
  margin: 0px;
  margin-bottom: 5px;
}

.settings__card-team_management--empty_wrapper p {
  font-family: "SF Pro Text" sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #191459;
  padding: 0px;
  margin: 0px;
}

.settings__card-team_management--empty_wrapper button {
  margin: 10px;
}

.settings__card-team_management--empty_wrapper {
  /* overwrite default bp card padding */
  margin-left: -20px;
  margin-right: -20px;
  margin-bottom: -20px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

.settings__card-team_management--team_wrapper {
  /* overwrite default bp card padding */
  margin-left: -20px;
  margin-right: -20px;
  margin-bottom: -20px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

.setting_logout_btn_container {
  display: flex;
  justify-content: flex-end;
  width: 770px;
}

.setting_logout_btn {
  width: 5rem;
  height: 2.5rem;
  color: #ffffff !important;
  background-color: #161414 !important;
}

.settings__card-input-list-item__input-icon {
  position: relative;
  right: 10px;
  top: 7px;
}

.flex-col {
  flex-direction: column;
}

.setting__resources-card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.setting__resources-card-wrapper {
  width: 370px;
  height: 240px;
  background: #ffffff;
  mix-blend-mode: normal;
  padding: 5px 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.setting__resources-card-title {
  color: #323232;
  margin-bottom: 20px;
  text-transform: capitalize;
}

.setting__resources-card__link-options {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.setting__resources-card__link {
  color: #6200ee;
}

.setting__resources-card__arrow-icon {
  width: 12px;
  transform: rotateZ(-35deg);
}

.build__version {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  font-size: 10px;
  font-weight: 600;
}

.build__version span {
  width: 40px;
  text-align: center;
  display: inline-block;
  background-color: #e2e7eb;
}

.settings__card-category-input-list-item {
  display: flex;
  align-items: flex-start;
  margin-top: 10px;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  height: 150px;
}

.setting__card-category-label {
  width: 192px;
  height: 69px;
  left: 109px;
  font-style: normal;
  font-weight: normal;
  /* font-size: 12px;
  line-height: 16px; */
}

.project_management_input {
  height: 40px;
  width: 200px;
}
