.global__operations-widget {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #f1f1f1 !important;
  border-radius: 5px;
  padding: 20px;
}

.operations__widget-title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  padding-bottom: 20px;
  margin: 0px;
  color: #333333;
}

.operations__widget-input {
  color: #333333;
}
