.umoja__header__outer-wrapper {
  display: flex;
  justify-content: space-between;
  height: 60px;
  margin: 0px;
  border-bottom: 1px solid #edeff1;
}

.umoja__header__profile-indicator {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  cursor: pointer;
}

.umoja__header__title {
  padding: 0;
  margin: 0;
  font-size: 20px;
  padding: 20px;
}

.umoja__header__name {
  font-weight: 500;
  margin-left: 10px;
  margin-right: 20px;
}

span.bp3-tag {
  background-color: #3a3a3a;
}

.umoja__header__popover {
  width: 10rem;
  height: 4rem;
  display: flex;
  justify-content: center;
  padding-top: 1rem;
  background-color: #ffffff;
}
