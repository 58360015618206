.new_project_card {
    width: 713px;
    height: 630px;
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
}

.new_project_card_header {
    height: 209px;
    display: flex;
    flex-direction: column;
    background-color: #6100ED;
    padding-top: 49px;
    padding-left: 52px;
    margin-bottom: 40px
}

.new_project_card_body {
    color: #989898;
    width: 361px;
    height: 105px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    font-size: 15px;
    margin-bottom: 50px
}