table {
  text-align: left;
  width: 100%;
}

th {
  color: #6200ee;
  font-size: 14px;
  padding-left: 30px;
}

th span {
  margin-right: 1rem;
}

th svg {
  vertical-align: middle;
}

tbody tr:nth-of-type(odd) {
  background: rgba(191, 204, 214, 0.2);
}

tr {
  padding-left: 10px;
}

th,
td {
  border-width: 1px;
  border-style: solid;
  border-color: rgba(16, 22, 26, 0.15);
  border-bottom: none;
  padding: 10px;
  font-size: 14px;
}

tbody tr:hover {
  background-color: rgba(98, 0, 238, 0.05);
}

th:nth-of-type(1),
td:nth-of-type(1) {
  border-left: none;
}

thead > tr:first-child th,
tbody > tr:first-child td {
  border-top: none;
}

th:is(:nth-child(n + 1)),
td:is(:nth-child(n + 1)) {
  border-right: none;
}

.drawer__wrapper {
  padding: 30px;
}

.participant__title-wrapper {
  display: flex;
  justify-content: space-between;
  height: 100px;
  width: 100%;
}

.participant__info-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 130px;
  width: 300px;
  /*white-space: normal;*/
}

.participant__info-name {
  margin: 0;
  padding: 0;
  font-size: 20px;
  /*line-height: 5px;*/
  text-align: match-parent;
  font-weight: bold;
  color: #323232;
}

.participant__info-type {
  text-transform: uppercase;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  font-variant: small-caps;
  color: #767676;
}

.participant__info-blurb {
  font-style: normal;
  font-family: "SF Pro Text", sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  padding-top: 10px;
  width: 100%;
  color: #767676;
}

.participant__info-actions {
  display: flex;
  align-items: center;
}

.participant__info-actions-button {
  background: linear-gradient(
      360deg,
      rgba(255, 255, 255, 1e-5) 0%,
      rgba(255, 255, 255, 0.1) 100%
    ),
    #137cbd !important;
  color: #ffffff !important;
  width: 130px;
  height: 40px;
  font-size: 16px !important;
  line-height: 20px !important;
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 3px;
}

.participant__info-pause-wallet-button {
  background: linear-gradient(
      360deg,
      rgba(255, 255, 255, 1e-5) 0%,
      rgba(255, 255, 255, 0.1) 100%
    ),
    #6e32fc !important;
  color: #ffffff !important;
  width: 130px;
  height: 40px;
  font-size: 16px !important;
  line-height: 20px !important;
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 3px;
}

.participant__info-disburse-wallet-button {
  background: linear-gradient(
      360deg,
      rgba(255, 255, 255, 1e-5) 0%,
      rgba(255, 255, 255, 0.1) 100%
    ),
    green !important;
  color: #ffffff !important;
  width: 130px;
  height: 40px;
  font-size: 16px !important;
  line-height: 20px !important;
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 3px;
}

.participant__info-export-button {
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.05) 0%,
      rgba(255, 255, 255, 5e-6) 100%
    ),
    #394b59 !important;
  color: #ffffff !important;
  /*width: 153px;*/
  height: 40px;
  font-size: 16px !important;
  line-height: 20px !important;
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 3px;
  white-space: nowrap;
}

.participant__info-cog {
  background: #2988c3;
  padding: 5px;
  border-radius: 25px;
  cursor: pointer;
}

.wallet__info-title {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 10px;
  text-transform: uppercase;
  font-variant: small-caps;
  color: rgba(24, 32, 38, 0.5);
}

.wallet__info-text {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  color: #182026;
}

/* drawer table has different padding than the main portal table */

.wallet__table-wrapper td {
  padding-left: 30px !important;
  padding-top: 10px;
  padding-bottom: 10px;
}

.wallet__table-wrapper th {
  padding-left: 30px !important;
  padding-top: 10px;
  padding-bottom: 10px;
}

/* table header & timeframe select styles */

.organization__wallet-explorer-table-wrapper {
  background-color: #f8fafc;
  height: 100%;
  border-top: 1px solid #edeff1;
  overflow-y: scroll;
}

.organization__wallet-explorer-table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.organization__wallet-explorer-table-header
  button.organization__wallet-filter-apply-button {
  background-color: none;
  border: solid 1px #6200ee !important;
  color: #6200ee;
}

/* info box */

.organization__wallet-info-box {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  align-items: center;
  min-height: 120px;
  max-height: 120px;
  padding-left: 30px;
  padding-right: 30px;
  border-top: 1px solid #edeff1;
  border-bottom: 1px solid #edeff1;

}

/* register wallet modal */

.register_wallet__modal_wrapper {
  padding: 20px;
  margin-bottom: -20px;
}

.register_wallet__modal_wrapper h1 {
  font-family: "SF Pro Text", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #182026;
}

.register_wallet__modal_wrapper p {
  font-family: "SF Pro Text", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: rgba(24, 32, 38, 0.5);
  margin-bottom: 30px;
}

.bp3-dialog-header {
  background: none !important;
  box-shadow: none !important;
}

/* override blueprint bottom margin */
.card__registration-number_input input {
  margin-bottom: 0px !important;
}

.transactions__loading_indicatior {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.merchant__preview-file_preview_wrapper {
  display: flex;
  align-items: center;
  padding-left: 30px;
  padding-right: 30px;
}

.merchant__preview-file_title {
  padding-right: 10px;
}

.merchant__preview-file_input {
  padding-right: 10px;
}

.merchant__preview-file_button {
  margin-right: 10px;
}

.pagination {
  margin-top: 0.25rem;
  display: flex;
  justify-content: space-evenly;
  /* border: 1px solid #e4e4e4; */
  padding: 0.5rem;
  height: 60px;
  width: 350px;
}

.search_input {
  -webkit-appearance: none;
  border: 1px solid #e4e4e4;
  /*padding: 0.5rem;*/
  width: 100%;
  max-width: 100%;
  background: #f0f0f0;
  height: 30px;
  align-items: center;
}

.wallet_filter_dropdown{
  width: 100%;
}

.filter_pop {
  width: 100%;
}

.pagination_button {
  width: 80px;
  height: 40px;
}

.reset_button {
  margin-top: 5px;
}
