.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.box {
  width: 100vw;
  height: 25%;
  display: flex;
  background-color: #2c2c2c;
  justify-content: center;
  align-items: center;
}

.box__left{
  background: #eeeefe;
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: column;
}

.box__container {
   display: flex;
   width: 100%;
   height: 100%;
   z-index: 2;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   background-color: #6200ee;
}

.box__container__left {
  display: flex;
  width: 100%;
  height: 70%;
  display: flex;
  background-color: white;
}

.box_container_header {
  display: flex;
  height: 500px;
  width: 90%;
  flex-direction: column;
  margin-left: 2rem;
  padding-right: 2rem;
}

 .logo__container {
   display: flex;
   width: 100%;
   height: 30%;
   flex-direction: row;
   justify-content: flex-start;
   font-size: 14px;
   font-family: "Roboto";
   font-weight: 500;
   border: "1px solid #eeeefe";
   align-items: center;
   text-align: center;
   color: #eeeefe;
 }

 .box_container_header--content {
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 19px;
      color: #ffffff;
      opacity: 0.7;
      padding-bottom: 10px;
  }
  .logo {
      margin-right: 0.5rem;
  }
  .Umoja_Lang_container {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      height: 10%;
  }

  .wrapper-infobox-display {
    display: none;
  }

  .private__policy__container {
    display: none;
  }

  .support__link {
    margin-top: 10px;
    margin-left: 20px;
    width: 50%
  }
 

@media screen and (min-width: 600px) {
  .container{
    flex-direction: row;
  }
  .box {
    height: 100%;
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;

  }
  .box__left {
    height: 100%;
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
  .box__container {
      display: flex;
      width: 70%;
      height: 70%;
      display: flex;
      background-color: #6200ee;
      border-radius: 20px 0px 0px 20px;
      margin-bottom: 40px;
   }
  .box__container__left {
      display: flex;
      width: 60%;
      height: 70%;
      display: flex;
      background-color: white;
      border-radius: 0px 20px 20px 0px;
      margin-bottom: 30px;
   }
   .logo__container {
      display: flex;
      width: 100%;
      height: 10%;
      flex-direction: row;
      justify-content: flex-start;
      font-size: 14px;
      font-family: "Roboto";
      font-weight: 500;
      margin-top: 2rem;
      border: "1px solid #eeeefe";
      align-items: center;
      text-align: center;
      color: #eeeefe;
   }
   .logo {
      margin-right: 0.5rem;
   }
   .box_container_header {
    display: flex;
    height: 500px;
    width: 90%;
    flex-direction: column;
    margin-left: 2rem;
    padding-right: 2rem;
   }
  .box_container_header--content {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #ffffff;
    opacity: 0.7;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .Umoja_Lang_container {
    display: none;
  }
    .wrapper-infobox {
      background: rgba(45, 45, 45, 0.3) !important;
      border-radius: 3px;
      padding: 0px;
      margin-left: 20px;
      margin-right: 0px; 
      margin-bottom: 20px;
      width: 80%;
    }

    .wrapper-infobox-display {
      display: flex;
    }

    .private__policy__container{
      display: block;
      flex-direction: column;
      width: 60%;
      justify-content: space-evenly;
    }
    
    .support__link {
      margin-left: 10px;
      width: 50%
    }
   
  
}