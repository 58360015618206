.button_basic {
    background-color: #E5E6E5 !important;
    border-radius: 20px;
    font-size: 12px;
    font-weight: 600;
    white-space: nowrap;
}

.create_group_button {
    margin-top: 10px;
}

.button_space {
    margin-right: 10px;
}

.button_remove {
    background-color: #CD4F48 !important;
    color: white !important;
    font-size: 12px;
    border-radius: 20px;
    font-weight: 600;
    text-align: center;
    white-space: nowrap;
}

.button_add {
    background-color: #2DC66B !important;
    color: white !important;
    font-size: 12px;
    border-radius: 20px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 10px;
}

.group_button {
    background-color: #6200ee !important;
    color: white !important;
}

.button__container {
    display: flex;
    justify-content: space-between;
    height: 10%;
    width: 100%;
    align-items: center;
    border-top: 1px solid #e4e4e4;
}

.search_input_container {
    width: 80%;
    margin-right: 10px;
}


.search_input {
    width: 70%;
}

.wallet__selector_input-wrapper_indicator--active .disburse-1 {
    fill: #6200ee !important;
}

.disbursement__panel-wallet_indicator--inner {
    display: flex;
    align-items: center;
    gap: 15px;
}

.disbursement__panel-wallet_indicator--active .disburse-1 {
    fill: #ffffff !important;
}

.disbursement__panel-wallet_indicator--active-text {
    color: #ffffff !important;
    font-weight: 600;
}

.no-bottom-margin {
    margin-bottom: 0;
}

.wallet_group_wrapper {
    display: flex;
    flex-direction: column;
    max-width: 900px;
    height: 78vh;
    padding-right:20px
}

.wallet_group_container {
    display: flex;
    flex-direction: row;
    height: 90%;
}

.wallet_group_action-footer {
    display: flex;
    align-items: center;
    height: 100%;
    width: 60%;
    justify-content: flex-start;
}

.wallet_group_action-footer-side {
    display: flex;
    align-items: center;
}

.wallet_group_list_wrapper {
    overflow: scroll;
    height: 85%;
    margin-bottom: 10px
}

.wallet_table_container {
    display: flex;
    justify-content: center;
    /*padding-top: 20px;*/
    margin-top: 10px;
    height: 10%;
    align-items: center;
}

.wallet_group_top_action_container {
    display: flex;
    justify-content: space-between;
    height: 15%;
    width: 100%;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    /*margin-top: 10px;*/
    border-bottom: 1px solid #e4e4e4;
}

.button_text {
    font-size: 12px;
    font-weight: bold;
}

.wallet_group_title_container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%
}

.wallet_group_top_bar {
    display: flex;
    flex-direction: row;
    height: 90%
}

.circle {
    height: 35px;
    width: 35px;
    background-color: #E5E6E5;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.disbursement__panel-wallet_indicator_wrapper{
    height: 70px;
}

.delete_dialog_container {
    height: 130px;
    width: 340px;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center
}

.delete_dialog_content {
    width: 100%;
    display: flex;
    justify-content: space-around;
    padding-left: 10px
}

.select_container_black {
    border: 1px solid #E5E6E5;
    border-radius: 20px;
    margin-right: 5px;
    height: 29px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 12px;
    white-space: nowrap;
    padding-right: 10px;
    padding-left: 10px;
}

.select_container_grey {
    background-color: #F2F6F9;
    border-radius: 20px;
    margin-right: 5px;
    height: 29px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 12px;
    color: #B2B2B2;
    white-space: nowrap;
    padding-right: 10px;
    padding-left: 10px;
}
