.activity_explorer_wrapper {
  display: flex;
  flex-direction: column;
  padding-right: 20px
}

.featured__charts-outer-wrapper {
  min-height: 200px;
}

.featured__charts-header-area {
  display: flex;
  background-color: #3a3a3a;
  min-height: 200px;
}

.featured__charts-chart-area {
  background-color: #2c2c2c;
}

.featured__charts-chart-select {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
}

.featured__charts-title {
  color: #fffffe;
  flex: 1;
}

.featured__charts-chart-labels {
  display: flex;
  text-align: baseline;
}

.featured__charts-chart-label {
  color: #fffffe !important;
}

.featured__charts-chart-select .bp3-tab-indicator {
  background-color: #fffffe !important;
}

.featured__charts-chart-data {
  display: flex;
  flex: 1;
  padding: 20px;
  justify-content: flex-end;
}

.featured__charts-chart-data-card {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: flex-end;
  justify-content: center;
  padding: 20px;
}

.featured__charts-chart-data-card .card-title {
  color: #fffffe;
  font-family: Roboto Mono;
  font-weight: 100;
  font-size: 47px;
  line-height: 62px;
  padding: 0;
  margin: 0;
  text-align: right;
  letter-spacing: -0.07em;
}

.featured__charts-chart-data-card .card-label {
  color: #fffffe;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-transform: lowercase;
  text-align: right;
  letter-spacing: 0.04em;
  font-variant: small-caps;
  opacity: 0.5;
  white-space: nowrap;
  overflow: hidden;
}

.featured__charts-timeframe-select-list {
  display: flex;
  text-align: baseline;
  justify-content: flex-end;
  padding-right: 40px;
}

.featured__charts-timeframe-select-list .bp3-tab-indicator {
  background-color: #fffffe !important;
}
