.dashboard__container {
  display: grid;
  grid-template: "sb sc";
  grid-template-columns: 270px 1fr;
  height: 100vh;
}

.bp3-transition-container {
  /* Overwrite default blueprint style for dropdown to overlap drawer overlay */
  z-index: 22 !important;
}

.dashboard__content {
  grid-area: sc;
  padding-right: var(--gap-size);
  overflow-y: scroll;
}

.dashboard__body {
  padding: 20px;
}
