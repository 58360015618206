/* umoja currency input */

.currency__input-wrapper {
  display: flex;
  margin-bottom: 0px !important;
}

.currency__input {
  margin-bottom: 0px;
  box-sizing: border-box;
  text-align: right;
  font-family: "Roboto Mono";
  width: 100%;
  border: none;
  font-size: 20px;
  padding-right: 20px;
}

.currency__input--filled {
  background-color: #6200ee;
  color: #ffffff;
}

.currency__input:focus {
  outline: none;
  border: none;
}

.currency__input-icon_wrapper {
  display: flex;
  width: 75px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* umoja date select */

.date__select-icon {
  padding: 6px;
}

.date__select-input {
  background: #ffffff;
  box-shadow: inset 0px 1px 1px rgba(16, 22, 26, 0.2);
  border-radius: 3px;
  vertical-align: middle;
}

/* remove padding from button to stop date and year */
.bp3-html-select.bp3-minimal select,
.bp3-select.bp3-minimal select {
  padding: 0;
}

/* umoja multi-select */

.multi__select-wallet-select {
  display: inline-block;
  width: 100%;
}

.multi__select-title-selection {
  /* must have static width, as dropdown is positioned absolute */
  min-width: 352px;
}

/* umoja popover select */

.popover__select-text_box {
  flex: 1;
  background-color: #deefff;
  color: #137cbd;
  padding: 10px;
  margin-right: 5px;
}

.popover__select-content {
  padding: 5px;
}

.popover__select-interval_selection {
  border: none;
  justify-content: left;
}

.popover__select-cog {
  border: none;
  background: none;
}
