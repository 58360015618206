.dashboard__sidebar {
  display: flex;
  flex-direction: column;
  grid-area: sb;
  border-right: 1px solid #edeff1;
  border-radius: 0 !important;

  /* The sidebar should render above any modal backdrop. */
  z-index: 21;
  background-color: #ffffff;
}

.sidebar__menu {
  padding: 8px;
}

.sidebar__menu .bp3-popover-wrapper,
.sidebar__menu span.bp3-popover-target {
  width: 100%;
}

.sidebar__menu span.bp3-popover-target > div button {
  justify-content: space-between;
  align-items: flex-start;
}

.sidebar__menu span.bp3-popover-target > div,
.sidebar__menu span.bp3-popover-target > div > * {
  width: 100%;
}

.sidebar__menu_secondary-wrapper {
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  padding-top: 10px;
  flex: 1;
}

.sidebar__menu_secondary-subwrapper {
  flex: 1;
}

.sidebar__item {
  padding: 10px 20px;
  font-weight: 700;
}

.sidebar__item a {
  color: #767676;
}

.sidebar__item > a > :nth-child(1) {
  padding-right: 20px;
}
