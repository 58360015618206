.reset-password-card {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60%;
  border-radius: 0px 20px 20px 0px !important;
  max-width: 800px;
}

.reset-password-form-wrapper {
  width: 85%;
}

.reset-password-form-header {
  text-align: center;
}

.reset-password-label {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #323232;
  padding-top: 10px;
  padding-bottom: 10px;
}

.reset-password-input-wrapper {
  height: 50px;
  margin-bottom: 10px;
}

.reset-password-button-wrapper {
  height: 50px;
  margin-bottom: 10px;
}

.reset-password-button {
  background-color: #6200ee !important;
  color: #ffffff !important;
  padding-left: 50px !important;
  padding-right: 50px !important;
  letter-spacing: 0.1 !important;
  font-weight: bold;
}

/* thin and tall viewport */
@media only screen and (max-width: 1000px) {
  .reset-password-label {
    font-size: 12px;
    padding: 2px;
  }
}
