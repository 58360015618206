.chart__widget-title {
  margin: 0px;
  padding: 0px;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500px;
}

.chart__widget-display-value {
  margin: 0px;
  padding: 0px;
  font-size: 22px;
  line-height: 26px;
  font-family: "Roboto Mono";
}

.chart__widget-line-wrapper {
  display: block;
  border-bottom: 1px solid #edeff1;
}

.chart__widget-x-axis {
  display: flex;
  justify-content: space-between;
  font-family: "Roboto Mono";
  font-weight: 600;
  color: #767676;
}

.chart__widget-bar-bottom {
  display: flex;
  justify-content: space-between;
  text-transform: uppercase;
  font-family: "Roboto Mono";
  font-weight: 600;
  color: #767676;
}

.chart__widget-bar-wrapper {
  width: 100%;
  min-height: 200px;
}

.spending_pattern_chart {
  background-color: #ffffff;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  border-bottom: 1px solid #edeff1;
  height: 80%;
  width: 100%;
}

.spending_pattern_chart__footer,
.demographic_insight_chart__footer {
  height: 40px;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  background-color: #ffffff;
  padding: 10px 20px;
  color: #767676;
}

.spending_pattern_chart__footer > span {
  font-size: 14px;
  line-height: 16px;
  font-weight: 700;
}

.demographic_insight_chart {
  background-color: #ffffff;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  border-bottom: 1px solid #edeff1;
}

.flex-display {
  display: flex;
  align-items: center;
}

.legend {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px;
}

.black {
  background: #000000;
}

.green {
  background: #28ceae;
}

.blue {
  background: #6100ed;
}

.orange {
  background: #ed8310;
}

.margin-right-15 {
  margin-right: 15px;
}

.demographic_insight_chart_font {
  font-family: SFPro;
  font-weight: 500;
}
