.reset-password-card {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 0px 0px 0px 20px !important;
  max-width: 100%
}

.reset-password-form-wrapper {
  width: 65%;
}

.reset-password-form-header {
  text-align: center;
}

.reset-password-label {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #323232;
  padding-top: 10px;
  padding-bottom: 10px;
}

.reset-password-input-wrapper {
  height: 50px;
  margin-bottom: 10px;
}

.reset-password-button-wrapper {
  height: 50px;
  margin-bottom: 10px;
}

.reset-password-button {
  background-color: #6200ee !important;
  color: #ffffff !important;
  padding-left: 50px !important;
  padding-right: 50px !important;
  letter-spacing: 0.1 !important;
  font-weight: bold;
}

.pin__reset__form {
  display: flex; 
  width: 100%; 
  justify-content: center; 
  height: 90%; 
  align-items: center
}

.pin__reset__success{
  width: 70%; 
  height: 50%; 
  display: flex
}

.pin__reset__input_wrapper {
  display: flex; 
  flex-direction: column; 
  width: 60%; 
  height: 90%; 
  justify-content: space-evenly; 
  align-items: center;
}

.input__wrapper {
  width: 200px;
  height: 60px;
  display: flex;
  flex-direction: column; 
  justify-content: center;
}

@media screen and (min-width: 600px) {
  .pin__reset__input_wrapper {
    display: flex;
    width: 60%;
    height: 50%;
    justify-content: space-evenly;
    align-items: center;
  }
}